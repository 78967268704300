import React from "react"
import { Link } from "gatsby"

import SearchInput from "../SearchInput"
import SearchPager from '../SearchPager';

import { useLunr } from '../../lib/hooks';

import css from "./SearchBlock.module.scss"

type Props = {
  initialQuery?: string
  initialLang?: string
  limit: number
  startPage: number
}

const SearchBlock = ({
  limit = 25,
  startPage = 0,
  initialQuery = "",
  initialLang = "en",
}) => {
  const [page, setPage] = React.useState(startPage);
  const [lang, setLang] = React.useState(initialLang);
  const [query, setQuery] = React.useState(initialQuery);

  const [searched, setSearched] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [startAt, setStartAt] = React.useState(0);
  const [searchReady, runSearch] = useLunr(lang, true, {
    waitTime: 500,
    waitCount: 40
  });
  const numResults = limit;

  const doSearch = async (currentQuery, firstRun = false) => {
    if (!currentQuery || !currentQuery.length) {
      setSearched(false);
      return;
    }
    setSearched(true);
    runSearch(currentQuery)
      .then(res => setResults(res))
      .catch(e => console.error(e));
  }

  const firstItemIndex = startAt
  const lastItemIndex = startAt + numResults

  const pagedResults =
    results.length > 0 &&
    results.slice(firstItemIndex, lastItemIndex).map((r, index) => (
      <li key={index}>
        <Link to={`/${r.Slug}`}>
          {r.Type}
          {": "}
          {r.Title}
        </Link>
      </li>
    ))

  React.useEffect(() => {
    setQuery(initialQuery)
    setLang(initialLang)
    if (initialQuery.length > 0) {
      doSearch(initialQuery, true)
    }
  }, [initialQuery, initialLang])

  return (
    <div className={css.searchBlock}>
      <SearchInput
        initialQuery={initialQuery}
        initialLang={initialLang}
        onSubmit={(q, l) => {
          setQuery(q)
          setLang(l)
          doSearch(q)
        }}
      />
      <p className={css.advancedSearch}><Link to={'/search/advanced/'}>Advanced Search</Link></p>
      <div className={css.searchResults}>
        {!searchReady && (
          <p>Preparing to search, please wait...</p>
        )}
        {searchReady && searched && (
          <>
            { results.length > 0 && (
              <>
                <ol>{pagedResults}</ol>
                <SearchPager
                  firstItemIndex={firstItemIndex}
                  lastItemIndex={lastItemIndex}
                  pageLength={numResults}
                  onSetStart={(newStart) => setStartAt(newStart)}
                  resultsCount={results.length}
                />
              </>
            )}
            {query.length > 0 && results.length === 0 && <p>No results found.</p>}
          </>
        )}
        {query.length === 0 && <p>Please enter a keyword above.</p>}
      </div>
    </div>
  )
}

export default SearchBlock
